<template>
  <dash-page class="wsRounded" no-padding-main main-background="white" >


    <template #default>
      <!-- Header -->
      <div class="d-flex justify-space-between px-8 py-3 pb-0 wsRounded"  >
        <div style="width: 100%">

          <div class="d-flex align-center">

            <div v-if="entity.courses">
              <v-btn v-if="entity.courses.length > 0"
                     :to="businessDashLink('courses/' + entity.courses[0].uuid + '/editor') "
                     outlined  icon class="mr-3" :color="wsDARKLIGHT">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </div>

            <!-- Lecture Name-->
            <div style="width: 100%">
              <wsEditorSingleLine output-format="text" @loaded="titleReady = true" v-model="entity.name[selectedLang]" :placeholder="$t('EnterLectureName')" :editorStyle="lectureNameStyle" />
              <div style="width: 100%" class="d-flex pr-10 align-center" v-if=" entity.topics.length > 0"  >
                <!-- Topic Name-->
                <wsEditorSingleLine output-format="text"

                                    :placeholder="$t('EnterTopicName')"
                                    :editorStyle="lectureTopicStyle"
                                    v-model="entity.topics[selectedTopic].name[selectedLang]"
                                    @loaded="topicReady = true"/>
              </div>
            </div>

          </div>

        </div>
        <!-- Language Switch-->
        <div>
          <!--        <v-skeleton-loader transition="fade" type="heading" v-if="!editorReady" />-->
          <!--        <v-skeleton-loader transition="fade" type="text@1" class="pr-8 mt-3" v-if="!editorReady" />-->
          <v-chip @click=" checkLectureNameTranslation(lang); selectedLang = lang;"  v-for="(lang) in lang" :key="lang"
                  class="mr-1 mt-2"
                  :outlined="selectedLang !== lang"
                  small >
            <!--      :color="text[lang] ? wsDARK : 'grey'" dark-->
            <h4>{{ lang }} </h4>
          </v-chip>
          <v-menu offset-y transition="slide-y-transition">

            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                     class="noCaps mt-2 px-0"
                     :color="wsDARK"
                     text  small >
          <span>
            <v-icon small>mdi-plus</v-icon>
            {{ $t('AddTranslation') }} </span>
              </v-btn>

            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item @click="addTranslation(lang)" v-for="lang in remainedLanguages" :key="lang">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ lang }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div style="position: sticky" v-if="entity.topics.length > 0" >

        <v-sheet  class="pa-6 ma-6 wsRoundedHalf">


          <wsEditorRegular @loaded="editorReady = true"
                           @save="saveLecture"
                           v-model="entity.topics[selectedTopic].content[selectedLang]" />

          <v-skeleton-loader  type="text@2" class="px-4 mt-3" v-if="!editorReady"  />
          <v-fade-transition mode="out-in">
            <v-sheet  v-if="!editorReady"
                      class="wsRoundedHalf d-flex align-center justify-center mx-4 mt-3"
                      color="grey lighten-2"
                      height="700"
                      style="cursor: progress" >
              <v-progress-circular color="white" indeterminate size="76"/>
            </v-sheet>
          </v-fade-transition>

        </v-sheet>

      </div>
    </template>



    <!-- SIDE MENU-->
    <template #side>
      <div class="d-flex justify-space-between align-center">
        <h3>{{ $t('LectureEditor') }}</h3>
        <v-btn @click="saveLecture"
               :loading="loading"
               :disabled="loading"
               class="noCaps"
               small outlined >{{ $t('Save') }}</v-btn>
      </div>

      <h5 class="mt-6">{{ $t('Topics') }}</h5>

      <v-list color="transparent" dense class="pt-3">
        <v-list-item-group mandatory   >
          <div v-for="(item,i) in entity.topics"  :key="i">
              <v-list-item color="white" @click="changeTopic(i)  " >
                <v-list-item-title>{{ i+1 }}. <span v-html="getTranslation(item.name)"></span></v-list-item-title>
                <v-list-item-icon @click="showDeleteTopic()"> <v-icon small>mdi-delete-outline</v-icon></v-list-item-icon>
              </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <v-btn text class="noCaps" @click="addTopic">
        <v-icon small class="mr-2">mdi-plus</v-icon>
        {{ $t('AddTopic')}}
      </v-btn>

      <h5 class="mt-6">{{ $t('Files') }} :</h5>

      <v-simple-table class="wsHoverDarker" :style="`background-color: ${wsDARK}`" dense>
        <tbody>
        <tr v-for=" (file,i) in entity.files "  :key="i + 'file'" class="pointer">
          <td width="10px"><v-icon small>mdi-file</v-icon></td>
          <td @click="openFile(file)" class="pl-0">
            <h5 class="font-weight-light">{{ file.name }}</h5>
          </td>
          <td width="10px"><v-btn @click="deleteFile( file.uuid, i )" small icon><v-icon small>mdi-delete-outline</v-icon></v-btn></td>
        </tr>
        </tbody>
      </v-simple-table>

      <v-btn text class="noCaps" @click="showUploadFile">
        <v-icon small class="mr-2">mdi-plus</v-icon>
        {{ $t('AddFile')}}
      </v-btn>

      <h5 class="mt-6">{{ $t('Course') }} </h5>
      <v-simple-table class="wsHoverDarker" :style="`background-color: transparent`" dense>
        <tbody>
        <tr style="background: transparent" v-for=" (item,i) in entity.courses "  :key="i + 'program'">
          <td width="10px"><v-icon small>mdi-file-document-multiple-outline</v-icon></td>
          <td style="cursor: pointer" @click="$router.push(businessDashLink('courses/' + item.uuid + '/editor') )" class="pl-0">
            <h5 class="font-weight-light">{{ item.name }}</h5>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

    </template>

    <template #dialog>
      <!-- Delete Topic Dialog -->
      <ws-dialog  :title="$t('DeleteTopic')"
                  :save-text="$t('Yes')"
                  @save="deleteTopic"
                  v-model="displayDeleteTopic">
        <h4 class="text-center">{{ $t('PleaseConfirmDeleteAction') }}</h4>
      </ws-dialog>
      <!-- Upload File Dialog -->
      <ws-dialog  :title="$t('UploadFile')"
                  :save-text="$t('Yes')"
                  @save="deleteTopic"
                  :show-save="false"
                  :cancel-text="$t('Finish')"
                  v-model="displayUploadFile">

        <wsImageUpload class="mt-6" @upload="uploadFile" :custom-text="true" >
          <h5 class="text-center font-weight-light"> {{$t('UploadFileInFormats')}} :  </h5>
          <h5 class="text-center mt-3"> .pdf, .doc, docx, .xls, .xlsx, .ppt, .pptx, .pages, .numbers, .keynote, .jpg, .jpeg, .png </h5>
        </wsImageUpload>

      </ws-dialog>
    </template>



  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import wsEditorRegular from "@/components/UI/wsEditorRegular";
import wsEditorSingleLine from "@/components/UI/wsEditorSingleLine";
import wsImageUpload from "@/components/UI/wsImageUpload";

export default {
  name: "lecturesEditor",
  props : ['uuid'],
  components : {
    wsEditorRegular,
    wsEditorSingleLine,
    wsImageUpload
  },
  data() {
    return {
      titleReady : false,
      topicReady : false,
      editorReady : false,
      loading : false,
      lectureNameStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 24px;\n' +
          '  font-weight: 600;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',
      lectureTopicStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 18px;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',
      test : null,

      selectedTopic : 0,
      entity : {
        name : {},
        topics : []
      },

      // dialogs
      displayDeleteTopic : false,
      displayUploadFile  : false,
      //lang
      selectedLang : 'ua',
      langBase : []

    }
  },
  computed: {

    blankTopic() {
      return {
        name : {
          [this.selectedLang] : "",
        },
        content : {
          [this.selectedLang] : "",
        }
      }
    },
    langDefault() {
      return [process.env.VUE_APP_I18N_LOCALE, process.env.VUE_APP_I18N_FALLBACK_LOCALE]
    },
    lang() {
      return [...this.langDefault, ...this.langBase ]
    },
    remainedLanguages(){
      let items = this.PARAMS_languages
      items = items.filter( el=> !this.lang.includes(el) )
      return items
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_LECTURE_EDIT',
        'SAVE_LECTURE',
        'REMOVE_LECTURE_FILE'
    ]),
    ...mapActions('upload', [
      'UPLOAD_PRIVATE_FILE',
      'GET_PRIVATE_FILE'
    ]),

    saveLecture() {
      this.notify(this.$t('ChangesSaved'))
      this.loading = true;
      this.SAVE_LECTURE(this.entity).then((out)=>{
        if ( out.data ) {
          this.entity = out.data.lecture
        } else {
          this.notify(this.$t('NetworkError'))
        }
        this.loading = false
      }).catch(()=>{
        this.notify(this.$t('NetworkError'))
        this.loading = false })
    },
    addTopic() {
      this.entity.topics.push(JSON.parse(JSON.stringify(this.blankTopic)) )
    },
    deleteTopic() {
      this.entity.topics.splice(this.selectedTopic,1)
      this.notify(this.$t('TopicDeleted'))
      this.displayDeleteTopic = false
    },
    showDeleteTopic(){
      this.displayDeleteTopic = true
    },
    showUploadFile() {
      this.displayUploadFile = true
    },
    uploadFile(file) {

      let data = {
        file : file ,
        params : {
          entity : 'lecture',
          entity_id : this.uuid,
          create_entity : true,
        }

      }
      this.UPLOAD_PRIVATE_FILE(data).then((out)=>{
        if ( out.result ) {
          this.notify(this.$t('FileUploaded'))
          this.entity.files.push(out.data.url)

          this.displayUploadFile = false
        }
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = false
      }).catch(()=>{
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = false
      })

    },
    async openFile(file) {
      let result = await this.GET_PRIVATE_FILE(file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    deleteFile(uuid, index) {
      this.loading = true
      let data = {
        lecture: this.uuid,
        file : uuid
      }
      this.REMOVE_LECTURE_FILE(data).then((out)=>{
        if ( out.result ) {
          this.entity.files.splice(index,1)
          this.notify(this.$t('FileDeleted'))
        }
        this.loading = false
      }).catch(()=> {
        this.loading = false
      })
    },


    //technical
    addTranslation(lang) {
      this.checkLectureNameTranslation(lang)
      this.langBase.push(lang)
    },
    getTranslation(translations) {
      return translations[this.selectedLang] ? translations[this.selectedLang] :  this.$t('NoTranslation')
    },
    checkLectureNameTranslation(lang, selectedTopic = null) {
      if ( selectedTopic === null ) { selectedTopic = this.selectedTopic }
      if ( !this.entity.name[lang] ) {
        this.entity.name[lang]='';
        this.entity = JSON.parse(JSON.stringify(this.entity)) }

      if ( this.entity.topics.length > 0 ) {

        if (!this.entity.topics[selectedTopic].name[lang]) {
             this.entity.topics[selectedTopic].name[lang] = ''
          this.entity = JSON.parse(JSON.stringify(this.entity)) }

        if (!this.entity.topics[selectedTopic].content[lang]) {
             this.entity.topics[selectedTopic].content[lang] = ''
          this.entity = JSON.parse(JSON.stringify(this.entity)) }


      }
    },

    changeTopic(index) {
      this.checkLectureNameTranslation(this.selectedLang, index);
      this.selectedTopic = index
    },


    initPage() {
      this.selectedLang = this.$i18n.locale
      if ( this.uuid ) {
        this.loading = true;
        this.GET_LECTURE_EDIT(this.uuid).then((out)=>{
          if ( out.data ) {
            this.entity = out.data.lecture


            if ( this.entity.name && Object.prototype.toString.call(this.entity.name) === '[object Object]')  {
              Object.keys(this.entity.name).forEach((key)=>{
                if (!this.lang.includes(key)) {
                  this.langBase.push(key)
                }
              })
            }

            if ( this.entity.topics.length === 0 ) {
              this.addTopic()
            }
          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(()=>{
          this.notify(this.$t('NetworkError'))
          this.loading = false })
      }
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
.singleLineEditor {

}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>